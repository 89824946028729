<template>
  <v-container class="custom_container" >
    <div class="card_container" >
      <img class="building_home" src="../assets/image/building.jpg">

      <div v-if="lang == 'th' " class="content_data" >
        <p class="description-main" :class="{ 'description-main-show': read_more_1 }" >
          อาคารการเรียนรู้พหุวิทยาการ หรือ Learning Exchange (LX) เป็นอาคาร 15 ชั้น ถูกออกแบบให้รองรับการเรียนรู้
          รูปแบบใหม่ ซึ่งมหาวิทยาลัยเทคโนโลยีพระจอมเกล้าธนบุรี
          มีความมุ่งหวังในการพัฒนาผู้เรียนมาอย่างต่อเนื่อง
          ตามสถานการณ์ในปัจจุบันของโลกที่มีการเปลี่ยนแปลงอยู่ตลอดเวลาอย่างรวดเร็ว ทำให้ผู้เรียนมีความต้องการที่เปลี่ยนแปลงไปเช่นกัน ความต้องการในปัจจุบันไม่ได้เพียงต้องการแค่ความรู้เฉพาะจากในห้องเรียน หรือตำราเรียนเท่านั้น ผู้เรียนยังต้องการสิ่งใหม่ๆ ผลลัพธ์ทางการเรียนที่สามารถ
          นำไปใช้ต่อได้ทันที ตอบโจทย์ประเภทของงานต่างๆ ซึ่งศาสตร์การเรียนรู้ดังที่มุ่งหวังจะไม่ใช่เฉพาะศาสตร์เป็นการเรียนรู้ที่ผสมผสานข้ามสาขาแลกเปลี่ยนเรียนรู้ร่วมกัน จึงเป็นที่มาของชื่อ<br>
          <b>“อาคารการเรียนรู้พหุวิทยาการ”</b> <br><br>

          โซนอาคารแบ่งออกเป็น 3 ส่วนหลักในแนวตั้ง ประกอบด้วย<br>
          โซนพื้นที่การแลกเปลี่ยนเรียนรู้ (ชั้น 1-4)<br>
          โซนทำงานวิจัย (ชั้น 5-6)<br>
          โซน ICT (ชั้น 7-15)
        </p>

      </div>
      <div v-else class="content_data" >
        <p class="description-main" :class="{ 'description-main-show': read_more_1 }" >
          The Learning Exchange Building (LX) is a 15-storey building designed to serve new learning approaches. King Mongkut’s University of Technology Thonburi has consistently determined to enhance learners’ ability in accordance with rapidly changing world situations, which subsequently cause learners’ needs to change. Nowadays, needs are not satisfied only by learning from classroom or textbook. However, learners also require new things and learning results that can be used instantly to serve various professional requirements. The determined learning discipline is not limited to one sole discipline but involves interdisciplinary studies in which knowledge from different disciplines can be exchanged and shared. The aforementioned learning approach is the origin of the name “Learning Exchange Building”.
          The LX Building comprises 3 major vertical zones, which are:

          1. Knowledge Exchange Zone (Level 1-4) <br>
          2. Research Zone (Level 5-6)<br>
          3. ICT Zone (Level 7-15)<br>
        </p>

      </div>


      <div align="center" ><br>
        <button v-if="read_more_1 != true" @click="read_more_1 = true"  class="read-more" >{{ lang == 'th' ? 'อ่านข้อมูลเพิ่มเติม' : 'ReadMore' }}</button>
      </div>

    </div><br>

    <div class="card_container " >

      <div class="tgbi_content" :class="{ 'tgbi_content-show': read_more_2 }" >
        <div class="content_data" ><br><br>
          <font class="header-content" > {{ lang == 'th' ? 'การเรียนรู้ภายในอาคาร' : 'The Building of Learning' }}</font>
          <p v-if="lang == 'th' " class="description-main"><br>
            เกณฑ์การประเมินความยั่งยืนทางพลังงานและสิ่งแวดล้อมไทย (อาคารเขียว) หรือ TREES จัดทำขึ้นด้วยความตระหนักถึงวิกฤตการณ์ทางพลังงานและสิ่งแวดล้อมที่รุนแรงและซับซ้อนมากขึ้น อาคารที่เหมาะสมจะเข้าเกณฑ์นี้ คือ อาคารที่มีการออกแบบและสร้างใหม่ทั้งหมด หรือเป็นโครงการปรับปรุงอาคารเก่าในระดับที่มีการเปลี่ยนแปลงครั้งใหญ่ ซึ่งอาคารการเรียนรู้พหุวิทยาการนี้ เริ่มโครงการก่อสร้างเมื่อวันที่ 25 กรกฎาคม 2559 แล้วเสร็จเมื่อวันที่ 10 มกราคม 2562 โดยเข้าเกณฑ์ดังกล่าวและได้รับการประเมินระดับ Platinum ซึ่งเป็นระดับสูงสุด
          </p>

          <p v-else class="description-main"><br>
            The assessment criteria of energy and environmental sustainability (green building) or TREES was formulated basing on concerns in energy and environmental crisis, which has become more severe and complicated. Qualified buildings can be either the entirely designed and built or the renovation project that involves major and massive transformation. The construction of Learning Exchange Building commenced on 25 July 2016 and finished on 10 January 2019. The building had met the criteria and was assessed as Platinum, which is the highest of its kind.
          </p>

        </div>
        <div align="center" ><br><br>
          <img class="tgbi_home" src="../assets/image/tgbi.png">
        </div>
        <div class="content_data" >
          <p v-if="lang == 'th' " class="description-main" ><br>
            เกณฑ์การประเมินความยั่งยืนทางพลังงานและสิ่งแวดล้อมไทย (อาคารเขียว) หรือ TREES จัดทำขึ้นด้วยความตระหนักถึงวิกฤตการณ์ทางพลังงานและสิ่งแวดล้อมที่รุนแรงและซับซ้อนมากขึ้น อาคารที่เหมาะสมจะเข้าเกณฑ์นี้ คือ อาคารที่มีการออกแบบและสร้างใหม่ทั้งหมด หรือเป็นโครงการปรับปรุงอาคารเก่าในระดับที่มีการเปลี่ยนแปลงครั้งใหญ่ ซึ่งอาคารการเรียนรู้พหุวิทยาการนี้ เริ่มโครงการก่อสร้างเมื่อวันที่ 25 กรกฎาคม พ.ศ. 2559 แล้วเสร็จเมื่อวันที่ 10 มกราคม พ.ศ. 2562 โดยเข้าเกณฑ์ดังกล่าวและได้รับการประเมินระดับ Platinum ซึ่งเป็นระดับสูงสุด
          </p>
          <p v-else class="description-main" ><br>
            The assessment criteria of energy and environmental sustainability (green building) or TREES was formulated basing on concerns in energy and environmental crisis, which has become more severe and complicated. Qualified buildings can be either the entirely designed and built or the renovation project that involves major and massive transformation. The construction of Learning Exchange Building commenced on 25 July 2016 and finished on 10 January 2019. The building had met the criteria and was assessed as Platinum, which is the highest of its kind.
          </p>
        </div>
        <div align="center" ><br><br>
          <img class="tgbi_home" src="../assets/image/cer.png">
        </div>
        <div class="content_data" >
          <p v-if="lang == 'th' " class="description-main" ><br>
            เมื่อวันที่ 1 กันยายน พ.ศ. 2563 สถาบันอาคารเขียวไทยได้มอบหนังสือรับรอง “อาคารการเรียนรู้พหุวิทยาการ” ให้แก่มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าธนบุรี ที่ได้ผ่านการตรวจประเมินอาคารตามเกณฑ์มาตรฐานการประเมินความยั่งยืนทางพลังงานและสิ่งแวดล้อมไทย สำหรับการก่อสร้างและปรับปรุงโครงการใหม่ TREES-NC ในระดับ PLATINUM
            อาคาร LX ถือเป็นอาคารต้นแบบของมหาวิทยาลัยเทคโนโลยีพระจอมเกล้าธนบุรี ที่ก้าวไปสู่ Green Building พร้อมมีฟังก์ชันการทำงานในตัวอาคารที่สามารถเรียนรู้ได้
            มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าธนบุรีมีความมุ่งหวังที่จะขยายรูปแบบอาคารที่ประหยัดพลังงานและผ่านเกณฑ์การตรวจประเมินอาคารตามมาตรฐานอาคารเขียว เช่น การปรับปรุงระบบประหยัดพลังงานในอาคารอเนกประสงค์ (UX) การใช้พลังงานทดแทนเพิ่มพลังงานไฟฟ้าไปยังอาคารอื่น การใช้ระบบเซนเซอร์ประหยัดไฟฟ้าและน้ำ เป็นต้น ซึ่งที่กล่าวมานั้นต้องรองรับการเรียนรู้รูปแบบใหม่ของทุกคนที่เข้ามาเรียนยังมหาวิทยาลัยเทคโนโลยีพระจอมเกล้าธนบุรีแห่งนี้
          </p>

          <p v-else class="description-main" ><br>
            On 1 September 2020, the Thai Green Building Institute had presented the certification of Learning Exchange Building to the King Mongkut’s University of Technology Thonburi for having surpassed the building assessment basing on the standard of Thai’s Rating of Energy and Environmental Sustainability for the construction and renovation project or TREES-NC at the Platinum level.
            The LX building is considered the prototype building of King Mongkut’s University of Technology Thonburi that has exceled as a Green Building, which is well equipped with functions that users can instigate.
            The King Mongkut’s University of Technology Thonburi has the determination to increase the number of energy conscious buildings that surpass the Green Building assessment, including the improvement of energy saving system at the multipurpose building (UX), the replacement of electric energy with renewable energy, as well as the use of power saving and water dispenser system. All of which must serve new learning approaches of all learners who come to the King Mongkut’s University of Technology Thonburi for learning.
          </p>

        </div>
      </div>



      <div align="center" >
        <button v-if="read_more_2 != true" class="read-more" @click="read_more_2 = true" >{{ lang == 'th' ? 'อ่านข้อมูลเพิ่มเติม' : 'ReadMore' }}</button>
      </div>

    </div><br>

    <div class="card_container" >
      <div align="center" ><br><br>
        <img class="tgbi_home" src="../assets/image/lx.png">
      </div>
      <br><br>
      <div class="list_button" >
        <button  class="button_custom btn_1" > {{ lang == 'th' ? 'การบริหารจัดการอาคาร' : 'Building Management' }}</button>
        <button @click="GotoPage('land')" class="button_custom btn_2" >{{ lang == 'th' ? 'ผังบริเวณและภูมิทัศน์' : 'Site and Landscape' }}  </button>
        <button @click="GotoPage('water')" class="button_custom btn_3" > {{ lang == 'th' ? 'การประหยัดน้ำ' : 'Water Conservation' }}</button>
        <button @click="GotoPage('energy')" class="button_custom btn_4" >{{ lang == 'th' ? 'พลังงานและบรรยากาศ' : 'Energy and Atmosphere' }}</button>
        <button @click="GotoPage('material')" class="button_custom btn_5" > {{ lang == 'th' ? 'วัสดุและทรัพยากรในการก่อสร้าง' : 'Materials and Resources' }} </button>
        <button @click="GotoPage('environment')" class="button_custom btn_6" > {{ lang == 'th' ? 'คุณภาพของสภาวะแวดล้อมภายในอาคาร' : 'Indoor Environmental Quality' }}  </button>
        <button @click="GotoPage('protect')" class="button_custom btn_7" > {{ lang == 'th' ? 'การป้องกันผลกระทบต่อสิ่งแวดล้อม' : 'Environmental Protection' }} </button>
        <button  class="button_custom btn_8" > {{ lang == 'th' ? 'นวัตกรรม' : 'Green Innovation' }} </button>
      </div>

      <div class="view-list" align="center" >
        <font>จำนวนคนเข้าชม <b>{{ visit }} คน</b></font>
      </div>

    </div>

  </v-container>

</template>
<script>
// @ is an alias to /src


import {getVisit} from "../lib/controller";
import {ResponseData} from "../lib/utility";

export default {
  name: 'Home',
  components:{},
  data: () => ({
    visit:0,
    read_more_1:false,
    read_more_2:false,
    lang: localStorage.getItem("lang"),
  }),
  methods:{
    GotoPage(page){
      this.$router.push({ path: page})
    },
    async GetData() {
      this.is_loading = true;

      await getVisit((response) => {

        const {status, data } = ResponseData(response)

        switch (status) {
          case 200:
            console.log(data)
              this.visit = parseInt(data+2314).toLocaleString();
            break;
          default:

            break;
        }

      });
    },
  },
  mounted() {
    setInterval(()=>
            this.lang = localStorage.getItem('lang') ,
        200)
    this.GetData();
  }
}
</script>
