

const axios = require('axios').default;
console.log(process.env.NODE_ENV)
console.log(process.env)
const host = (location.host).split(":");
let baseUrl;
let baseUrlMain;
let service_api;
let position_amount;
let machine_number;
let production  = true;

baseUrl = production == false ? "http://"+host[0]+":8000/api" : "https://kmutt.commsk.dev/api";
baseUrlMain = production == false ? "http://"+host[0]+":8000" : "https://kmutt.commsk.dev/" ;

console.log(machine_number)

const instance = axios.create({
    baseURL: baseUrl,
    timeout: 10000,
    headers: {
        'X-Custom-Header': 'foobar',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
    }
});





const API = {
    TGBICATEGORY: '/tgbi_category',
    TGBIDETAIL: '/tgbi_detail',
    Visit: '/visits',
}




export {API,instance,baseUrl,baseUrlMain,position_amount,service_api };
