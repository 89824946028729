<template>
      <div style="cursor: pointer" @click="this.cb" v-if="lang == 'th'" >
        <img class="img-fluid" :src="baseUrl+'/'+JSON.parse(data.image_icon).path"  ><br>
      </div>
      <div style="cursor: pointer" @click="this.cb" v-else >
      <img class="img-fluid"  :src="baseUrl+'/'+JSON.parse(data.image_icon_en).path"  ><br>
      </div>
</template>

<script>

import  {baseUrlMain } from "../const/api";

export default {
  props:["data","cb"],
  name: "CategoryCard",
  data:()=>(
      {
        lang:"th",
        baseUrl:baseUrlMain,
      }
  ),
  mounted() {
    setInterval(()=>
            this.lang = localStorage.getItem('lang') ,
        1000)
  }
}
</script>

<style scoped>
.img-mask{
  margin-top: 1rem;
  width: 80%;
}
.img-mask img{
  width: 100%;
  border-radius: 10px;
}
.container-card{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
}
.card{
  width: 80%;
  max-width: 20rem;
  background: white;
  border-radius:10px ;
  font-size: 2rem;
  min-height: 15rem;
  max-height: 30rem;
}
</style>
