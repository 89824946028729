<template>
  <div>
  <v-container class="container-fluid-custom" fluid >

    <img class="img-fluid" v-if="lang == 'th'" src="../assets/image/water.png">
    <img class="img-fluid" v-else src="../assets/image/water_en.png">

  </v-container>
  <v-container><br>
    <v-row>
      <v-col v-for="result in list_data" :key="result.id" cols="6" align="center" >
        <CategoryCard
            :data="result"
            :cb="()=>  GotoPageDetail(result.id)"
        />
      </v-col>
    </v-row>
  </v-container>
  </div>
</template>
<script>
// @ is an alias to /src


import {getTGBICategory} from "../lib/controller";
import {ResponseData} from "../lib/utility";
import CategoryCard from "../components/CategoryCard";

export default {
  name: 'AreaPlan',
  components:{
    CategoryCard
  },
  data: () => ({
    list_data:[],
    lang:localStorage.getItem('lang'),
  }),
  methods:{
    GotoPageDetail(id){
      this.$router.push({ path: "detail/"+id})
    },
    async GetData() {

      this.is_loading = true;

      await getTGBICategory("water", (response) => {

        const {status, data } = ResponseData(response)


        switch (status) {
          case 200:
            this.list_data = data;
            break;
          default:

            break;
        }

      });
    },
  },
  async mounted() {
   await this.GetData();

    setInterval(()=>
            this.lang = localStorage.getItem('lang') ,
        200)

  }
}
</script>
