<template>
  <div class="flie_list" >
    <v-icon>mdi-file-document-outline</v-icon>
    <div><a target="_blank" :href="baseUrl+'/'+JSON.parse(data.data).path" >{{ JSON.parse(data.data).name_old }}</a></div>
  </div>

</template>

<script>
import  {baseUrlMain } from "../const/api";
export default {
  props:["data"],
  name: "Docs",
  data(){
    return {
      baseUrl:baseUrlMain
    }
  }
}

</script>

<style scoped>
.flie_list{
  color: black;
  font-size: 2rem;
  display: flex;
  padding: 1rem;
  gap: 15px;
}
.flie_list i{
  font-size: 2rem;
}
a{
  text-decoration: none;
  color: black;
}
</style>
