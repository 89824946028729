const language = {
    state: {
        lang: localStorage.getItem('lang'),
    },
    mutations: {
        switchLang(state) {
            if(state.lang === 'th'){
                state.lang = 'en';
            }else{
                state.lang = 'th';
            }
        },
    },
    actions: {
        switchLang({ commit }) {
            commit('switchLang');
        },
    },
    getters: {
        getLang(state) {
            return state.lang;
        },
    },
};

export default language;
