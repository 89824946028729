<template>
  <v-container class="custom_container" >
    <div v-if="content" class="card_container" >
      <div v-if="lang == 'th'" >
      <img class="cover_detail" :src="baseUrl+'/'+JSON.parse(content.image_cover).path" />
        </div>
      <div v-else >
        <img class="cover_detail" :src="baseUrl+'/'+JSON.parse(content.image_cover_en).path" />
        </div>
      <br>

      <v-row v-if="image_position.length > 1" >

        <v-col
            v-for="result in image_position"
            :key="result.id"
            class="d-flex child-flex"
            cols="4"
        >
          <a :href="baseUrl+'/'+JSON.parse(result.data).path" data-fancybox="gallery" data-caption="Caption #1">
            <v-img
                :src="baseUrl+'/'+JSON.parse(result.data).path_thumb"
                :lazy-src="baseUrl+'/'+JSON.parse(result.data).path_thumb"
                aspect-ratio="1"
                cover
                class="bg-grey-lighten-2"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey-lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </a>
        </v-col>

      </v-row>


      <v-row v-else >

        <v-col
            v-for="result in image_position"
            :key="result.id"
            class="d-flex child-flex"
            cols="12"
        >
          <a :href="baseUrl+'/'+JSON.parse(result.data).path" data-fancybox="gallery" data-caption="Caption #1">
            <v-img
                :src="baseUrl+'/'+JSON.parse(result.data).path"
                :lazy-src="baseUrl+'/'+JSON.parse(result.data).path"
                class="bg-grey-lighten-2"
            >
            </v-img>
          </a>
        </v-col>

      </v-row>



      <div class="content_data" >
        <div class="container-html" >
          <div  v-if="lang == 'th'"  v-html="content.content" ></div>
          <div  v-else  v-html="content.content_en" ></div>
        </div>


        <v-carousel
            cycle
            hide-delimiter-background
            show-arrows="hover"

            v-if="images" >
          <v-carousel-item
              v-for="(item,i) in images"
              :key="i"


          >
            <v-sheet
            >

                  <a :href="baseUrl+'/'+JSON.parse(item.data).path" data-fancybox="gallery_image" :data-caption="JSON.parse(item.data).name_old">
                    <img class="custom-carousel" :src="baseUrl+'/'+JSON.parse(item.data).path" >
                  </a>
            </v-sheet>
          </v-carousel-item>



        </v-carousel>

      </div>

    </div><br>

    <div class="card_container pa-4" >
      <div class="content_data" >
        <div class="container-html" >
          <div v-if="lang == 'th'" style="font-size: 2rem"  v-html="content.des" > </div>
          <div v-else style="font-size: 2rem"  v-html="content.des" ></div>
        </div>


      </div>



    </div><br>

    <div v-if="content.link1 == '' && content.link2 == '' && content.link3 == '' " class="card_container pa-4" >
      <div class="content_data" >
        <h6 style="font-size: 3rem;margin-left: 1rem;margin-top: 1rem;margin-bottom: 1rem" >ลิ้งที่มาของข้อมูล</h6>
        <a style="margin-left: 1rem;font-size: 1.5rem;margin-top: 1rem;margin-bottom: 1rem;color: #0c0c0c" v-if="content.link1" :href="content.link1"  >{{ content.link1 }}</a><br>
        <a style="margin-left: 1rem;font-size: 1.5rem;margin-top: 1rem;margin-bottom: 1rem;color: #0c0c0c" v-if="content.link1" :href="content.link2"  >{{ content.link2 }}</a><br>
        <a style="margin-left: 1rem;font-size: 1.5rem;margin-top: 1rem;margin-bottom: 1rem;color: #0c0c0c" v-if="content.link1" :href="content.link2"  >{{ content.link2 }}</a><br>
      </div>
    </div><br>

    <div v-if="docs.length !== 0" class="card_container pa-4" >
      <div class="content_data" >
        <h6 style="font-size: 3rem;margin-left: 1rem;margin-top: 1rem;margin-bottom: 1rem" >เอกสารที่แนบมา</h6>
        <v-row v-for="result in docs" :key="result.id" >
          <Docs
              :data="result"
          />
        </v-row>

      </div>
    </div><br>


    <div v-if="video.length !== 0" class="card_container pa-4" >
      <div class="content_data" >
        <h6 style="font-size: 3rem;margin-left: 1rem;margin-top: 1rem;margin-bottom: 1rem" >วีดีโอที่เกี่ยวข้อง</h6>
        <v-row v-for="result in video" :key="result.id" >
          <v-col cols="6" >
            <video controls class="video_container" >
              <source  :src="baseUrl+'/'+JSON.parse(result.data).path" type="video/mp4">
              Your browser does not support HTML video.
            </video>
          </v-col>
        </v-row>

      </div>
    </div><br>



  </v-container>

</template>
<script>
// @ is an alias to /src


import {getTGBIDetail} from "../lib/controller";
import {ResponseData} from "../lib/utility";
import  {baseUrlMain } from "../const/api";
import Docs from "../components/Docs";

export default {
  name: 'Detail',
  components:{Docs},
  data(){
    return {
      baseUrl: baseUrlMain,
      content: [],
      images: [],
      image_position: [],
      docs: [],
      video: [],
      lang:localStorage.getItem('lang'),
    }
},
  methods:{
    GotoPage(page){
      this.$router.push({ path: page})
    },
    async GetData() {

      this.is_loading = true;

      await getTGBIDetail(this.$route.params.id, (response) => {

        const {status, data } = ResponseData(response)


        switch (status) {
          case 200:
            console.log(data.media)
            this.content = data;
            this.images = data.media.filter((result)=> result.media_type == "image" )
            this.image_position = data.media.filter((result)=> result.media_type == "image_position" )
            this.docs = data.media.filter((result)=> result.media_type == "docs" )
            this.video = data.media.filter((result)=> result.media_type == "video" )

              console.log(this.content)
              console.log(this.images)
              console.log(this.image_position)
              console.log(this.docs)
              console.log(this.video)

            break;
          default:

            break;
        }

      });
    },
  },
  async mounted() {
    await this.GetData();

    setInterval(()=>
            this.lang = localStorage.getItem('lang') ,
        200)
  }
}
</script>

