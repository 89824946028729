<template>
  <div>
      <div v-if="is_loading" class="loading_content" >
          <div class="content" >
            <img class="building_img" src="./assets/image/building.png" >
            <img class="logo_content" src="./assets/image/logo_content.png" >
            <div class="loading_bar" >
              <div class="progress" ></div>
            </div>
            <img class="group_" src="./assets/image/group.png" >

          </div>

    </div>

    <nav >
    <div class="menu" >
      <button @click="drop_down_menu == true ? drop_down_menu = false : drop_down_menu = true " >
        <img class="icon-rotate menu-icon" :class="{ 'icon-rotate-to': drop_down_menu }" src="./assets/image/menu.png" ></button>
     <img class="logo-menu" src="./assets/image/logo.png" >
      <button @click="Setlang()" >
        <img v-if="lang == 'th' " class="lang-menu" src="./assets/image/EN.png" >
        <img v-else  class="lang-menu" src="./assets/image/TH.png" >
      </button>
    </div>
    <div class="drop-down" :class="{ 'drop-down-show': drop_down_menu }" >
      <ul  class="drop-down-ul" >
        <li>
           <a @click="GotoLink('https://kmuttmainapp.commsk.dev/')" > <img class="icon-list-menu" src="./assets/image/home_icon.png"> {{ lang == 'th' ? 'พื้นที่ส่งเสริมการเรียนรู้ทางกายภาพ นอกห้องเรียน' : 'KNOWLEDGE SPOT IN KMUTT' }} </a>
        </li>
        <li>
          <a @click="GotoPage('/')" > <img class="icon-list-menu" src="./assets/image/home_icon.png"> {{ lang == 'th' ? 'อาคารการเรียนรูู้พหุวิทยาการ' : 'LX LEARNING EXCHANGE' }} </a>
        </li>
        <li style="padding-bottom:0rem" >
           <a @click="drop_down_tgbi == true ? drop_down_tgbi = false : drop_down_tgbi = true " ><img  class="icon-rotate icon-list-menu" :class="{ 'icon-rotate-to': drop_down_tgbi }" src="./assets/image/pointer.png"> TGBI</a>

          <ul class="drop-down-sub" :class="{ 'drop-down-sub-show': drop_down_tgbi }" >
            <li @click="drop_down_menu = false" >
              <a class="disable-link" > {{ lang == 'th' ? 'การบริหารจัดการอาคาร' : 'Building Management' }} </a>
            <li @click="drop_down_menu = false" >
            <router-link  to="/land"> {{ lang == 'th' ? 'ผังบริเวณและภูมิทัศน์' : 'Site and Landscape' }}</router-link>
            </li>
            <li @click="drop_down_menu = false" >
              <router-link  to="/water"> {{ lang == 'th' ? 'การประหยัดน้ำ' : 'Water Conservation' }} </router-link>
            </li>
            <li @click="drop_down_menu = false" >
              <router-link  to="/energy">{{ lang == 'th' ? 'พลังงานและบรรยากาศ' : 'Energy and Atmosphere' }} </router-link>
            </li>
            <li @click="drop_down_menu = false" >
              <router-link  to="/material"> {{ lang == 'th' ? 'วัสดุและทรัพยากรในการก่อสร้าง' : 'Materials and Resources' }} </router-link>
            </li>
            <li @click="drop_down_menu = false" >
              <router-link  to="/environment">{{ lang == 'th' ? 'คุณภาพของสภาวะแวดล้อมภายในอาคาร' : 'Indoor Environmental Quality' }} </router-link>
            </li>
            <li @click="drop_down_menu = false" >
              <router-link  to="/protect"> {{ lang == 'th' ? 'การป้องกันผลกระทบต่อสิ่งแวดล้อม' : 'Environmental Protection' }} </router-link>
            </li>
            <li @click="drop_down_menu = false" >
              <a class="disable-link" > {{ lang == 'th' ? 'นวัตกรรมสีเขียว' : 'Green Innovation' }} </a>
            </li>
          </ul>

        </li>

        <li>
          <a  > <img class="icon-list-menu" src="./assets/image/quiz.png"> {{ lang == 'th' ? 'แบบทดสอบ' : 'Quiz' }} </a>
        </li>

      </ul>
    </div>
    </nav>
    <div style="padding-top: 10rem" >
      <router-view/>
    </div>

  </div>
</template>

<script>



export default {
  name: 'App',

  components: {},
  data() {
    return {
      drop_down_tgbi: false,
      drop_down_floor: false,
      drop_down_menu: false,
      is_loading: true,
      lang: localStorage.getItem('lang'),
    }
  },
  methods: {
    GotoLink(page){
      window.location = page;
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    GotoPage(page){
      this.$router.push({ path: page})
    },
    Setlang(){
      localStorage.setItem("lang",this.lang == 'en' ? 'th':'en')
      this.lang = localStorage.getItem("lang")
    },
  },
  mounted() {
    setTimeout(()=>{
      this.is_loading = false;
    },7000);
  },
  beforeUnmount() {

  },
  created() {
    //console.log(process.env)
  }
};

</script>
<style>
.footer_custom {
  justify-content: center;
}

.v-toolbar__content {
  display: flex;
  height: 64px;
  width: 100%;
  justify-content: space-between;
}

.version_show {
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.v-application .blue.lighten-5 {
  width: 300px !important;
}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px !important;
}

.v-application ul, .v-application ol {
  padding-left: 55px !important;
}

.version_check {
  font-size: 0.9rem;
  padding: 1rem;
  color: #b3a6a6;
}
</style>
